import { tryParse } from "../core/util";

async function getAccessToken(auth0) {
	const user = auth0.user;
	const getAccessTokenSilently = auth0.getAccessTokenSilently;

	if (user) {
		return await getAccessTokenSilently();
	}
}

function wrapFetchWithBearerToken(url, method, accessToken) {
	return async function (payload) {
		const body = JSON.stringify(payload);
		const rsp = await fetch(url, {
			method,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
			body,
		});

		if (rsp.status === 401) {
			window.location.reload();
		}
		if (rsp.status >= 500) {
			console.log(rsp);
			throw await rsp.json();
		}

		return await rsp.json();
	};
}

function wrapUploadWithBearerToken(url, accessToken) {
	return async function ({ fields, file, onComplete }) {
		const payload = new FormData();
		payload.append("file", file);

		for (const key in fields) {
			payload.append(key, fields[key]);
		}

		const xhr = new XMLHttpRequest();
		xhr.open("POST", url);
		xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`);
		xhr.onreadystatechange = function () {
			if (xhr.readyState === XMLHttpRequest.DONE && xhr.status == 200) {
				console.log("upload complete!", xhr.response);
				if (typeof onComplete == "function") {
					onComplete(tryParse(xhr.response));
				}
			}
		};
		xhr.send(payload);
	};
}

function hasRole({ roles }, required) {
	if (typeof(required) === 'object' && required.length) {
		for (const target of required) {
			if (roles.indexOf(target) >= 0) {
				return true;
			}
		}

		return false;
	}

	return roles && roles.length && roles.indexOf(required) >= 0;
}

function hasFeature({ features }, required) {
	return features && features.length && features.indexOf(required) >= 0;
}

function wrapEventCreate (token){
  return wrapFetchWithBearerToken("/api/domain/eventCreate", "post", token);
}

export { 
  getAccessToken, 
  wrapFetchWithBearerToken, 
  wrapUploadWithBearerToken, 
  hasRole, 
  hasFeature,
  wrapEventCreate 
};