
import './tailwind.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { Auth0Provider } from "@auth0/auth0-react";
import {BrowserRouter} from "react-router-dom";

import App from './app/App';

const onRedirectCallback = async (appState) => {
  if (appState?.returnTo) {
    window.location.assign(appState.returnTo);
  }
};

const root = ReactDOM.createRoot(document.getElementById('app-root'));
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <Auth0Provider
        domain="auth.eetech.com"
        clientId="HnIoYSR4pmwfuiFz3JMyjjnPOfVvFr1U"
        audience="https://dev-f94jvqdm.us.auth0.com/api/v2/"
        scope="openid profile email"
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens="true"
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Auth0Provider>
    </RecoilRoot>
  </React.StrictMode>
);
