function extend(target, payload) {
  for (const key in payload) {
    target[key] = payload[key];
  }
}

function deepExtend(target, payload) {
  for (const key in payload) {
    if (typeof(payload[key]) == 'object') {
      if (!target[key]){
        target[key] = {};
      }

      deepExtend(target[key], payload[key]);
    }
    else {
      target[key] = payload[key];
    }
  }

  return target;
}

function deepCopy(payload){
  return JSON.parse(JSON.stringify(payload));
}

function tryParse(str) {
  if (typeof(str) == "object") {
    return str;
  }

  let json = {};

  try {
    json = JSON.parse(str);
  } catch (ex) {
    console.error("safeParse", ex);
    return str;
  }

  return json;
}

export { extend, deepExtend, deepCopy, tryParse };
