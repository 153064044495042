import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useSetRecoilState } from "recoil";

import { tokenState } from "./tokenState";
import { userState } from "./userState";
import { customerState } from "./customerState";
import { userRolesState } from "./userRolesState";
import { userFeaturesState } from "./userFeaturesState";
import Login from "./Login";
import Logout from "./Logout";

import { getAccessToken, wrapFetchWithBearerToken } from "./util";
import Modal from "../core/controls/Modal";

function UserStatus() {
	const auth0 = useAuth0();
	const user = auth0.user;

	const setTokenState = useSetRecoilState(tokenState);
	const setUserState = useSetRecoilState(userState);
	const setCustomerState = useSetRecoilState(customerState);
	const setUserRolesState = useSetRecoilState(userRolesState);
	const setUserFeaturesState = useSetRecoilState(userFeaturesState);

	const [token, setToken] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [customer, setCustomer] = useState({});

	useEffect(() => {
		async function getToken() {
			const _token = await getAccessToken(auth0);
			setToken(_token);
			setTokenState(_token);
			setUserState(user);
			const redirect_url = localStorage.getItem("redirect_url");
			if (redirect_url) {
				localStorage.removeItem("redirect_url");
			}
		}

		async function fetchData() {
			const ensureUser = wrapFetchWithBearerToken("/api/user", "post", token);
			let result;
			try {
				result = await ensureUser({
					email: user.email,
				});
			} catch (ex) {
				console.log(ex);
				setErrorMessage("Connection error! API unavailable!");
				return;
			}

			const getUser = wrapFetchWithBearerToken("/api/user", "get", token);
			try {
				result = await getUser();
			} catch (ex) {
				console.log(ex);
				if (ex.reason) {
					setErrorMessage(ex.reason);
				}

				return;
			}

			const _customer = {
				customerId: result.user.customerId,
				customerName: result.user.customerName,
				pixelScriptUrl: result.user?.pixelScriptUrl || "",
				active: result.user?.active || true,
			};
			setCustomer(_customer);
			setCustomerState(_customer);

			setUserRolesState(result.user.roles);
			setUserFeaturesState(result.user.features);
		}

		if (user && token) {
			fetchData();
		} else if (user) {
			getToken();
		}
	}, [user, token]);

	return (
		<div>
			{errorMessage ? (
				customer && !customer.active ? (
					<Modal>
						<span className="text-black">{errorMessage}</span>
					</Modal>
				) : (
					<>
						<span className='error-message'>{errorMessage}</span>
					</>
				)
			) : (
				<>
					{user && user.email ? (
						<>
							<div>
								{user.email} | <Logout />
							</div>
						</>
					) : (
						<Login />
					)}
				</>
			)}
		</div>
	);
}

export default UserStatus;
