import { useAuth0 } from "@auth0/auth0-react";

function Login() {
	const { loginWithRedirect } = useAuth0();
	function handleLogin() {
		const redirect_url = localStorage.getItem("redirect_url");
		loginWithRedirect({
			appState: {
				returnTo: redirect_url || window.location.pathname, // here
			},
		});
	}
	return (
		<button type='button' onClick={handleLogin}>
			Log In
		</button>
	);
}

export default Login;
