import { lazy, Suspense, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Spinner from "../core/controls/Spinner";

import Header from "./Header";
import Footer from "./Footer";
import Navigation from "./Navigation";
import Home from "./Home";
import About from "./About";

import ToastList from "../core/controls/Toasts/ToastList";
import UserStatus from "../auth/UserStatus";


const CustomerList = lazy(() => import("../crm/customers/CustomerList"));
const ServicePlanList = lazy(() => import("../crm/servicePlans/ServicePlanList"));
const UserList = lazy(() => import("../crm/users/UserList"));

const UploadFile = lazy(() => import("../reports/files/UploadFile"));
const DownloadsList = lazy(() => import("../reports/files/DownloadsList"));

const CustomReportsList = lazy(() => import("../reports/custom/CustomReportsList"));
const CustomReport = lazy(() => import("../reports/custom/CustomReport"));

const ReportList = lazy(() => import("../reports/native/reports/ReportList"));
const Report = lazy(() => import("../reports/native/reports/Report"));


const PartSearch = lazy(() => import("../domain/PartSearch"));
const PartCompare = lazy(() => import("../domain/PartCompare"));

const ChannelManager = lazy(() => import("../domain/reports/ChannelManager"));
const PricingAndInventory = lazy(() => import("../domain/reports/PricingAndInventory"));
const BuyOpportunity = lazy(() => import("../domain/reports/BuyOpportunity"));

const NewProductIntroduction = lazy(() => import("../domain/reports/NewProductIntroduction"));

const ComplianceInsights = lazy(() => import("../domain/reports/ComplianceInsights/ComplianceInsights"));
const PartSubstances = lazy(() => import("../domain/reports/ComplianceInsights/PartSubstances"));
const SubstanceParts = lazy(() => import("../domain/reports/ComplianceInsights/SubstanceParts"));

const AccountInsights = lazy(() => import("../domain/reports/AccountInsights/AccountInsights"));
const LeadGen = lazy(() => import("../domain/reports/AccountInsights/LeadGen"));
const PixelInstallInstructions = lazy(() => import("../domain/reports/AccountInsights/PixelInstallInstructions"));

const Top500Parts = lazy(() => import("../domain/Top500Parts"));
const ProductMovement = lazy(() => import("../domain/ProductMovement"));

const PartlistManager = lazy(() => import("../domain/partlists/PartlistManager"));
const PartlistUploadManager = lazy(() => import("../domain/partlists/PartlistUploadManager"));
const Partlist = lazy(() => import("../domain/partlists/Partlist"));

const SubstanceListManager = lazy(() => import("../domain/substances/SubstanceListManager"));
const SubstanceList = lazy(() => import("../domain/substances/SubstanceList"));

const AlertManager = lazy(() => import("../domain/alerts/AlertManager"));

const ConfigList = lazy(() => import("../reports/native/configs/ConfigList"));

function App() {
	const { isAuthenticated, isLoading } = useAuth0();
	const location = useLocation();

	document.title = "EETech Data Portal";
	useEffect(() => {
		console.log("location: ", location);
		if (location.pathname && location.search) {
			localStorage.setItem("redirect_url", `${location.pathname}${location.search}`);
		}
	}, []);

	if (isLoading) {
		return (
			<div className='App container mx-auto px-8'>
				<Header />
				<Spinner />
				<Footer />
			</div>
		);
	}

	if (!isAuthenticated) {
		return (
			<div className='App container h-screen flex justify-between flex-col mx-auto px-8'>
				<div />
				<div className='flex mx-auto bg-white drop-shadow-lg h-32 rounded-xl px-5 flex-col items-center justify-around'>
					<span className='tracking-wide font-medium text-gray-800'>
						Welcome to <span className='font-extrabold text-black'>EE</span>Tech Data Portal
					</span>
					<div className='hover:bg-black hover:text-white transition-all duration-300 ease-in-out border-black border rounded-lg px-3 py-1'>
						<UserStatus />
					</div>
				</div>
				<Footer />
			</div>
		);
	}

	return (
		<div className='App'>
			<>
				<div className='page flex flex-col'>
					<ToastList />
					<Header />
					<Navigation />
					<div className='pl-[100px] pr-5 gap-5 mt-[64px]'>
					<Suspense fallback={<Spinner />}>
						<Routes>
							<Route exact path='/' element={<Home />} />
							<Route path='/about' element={<About />} />

							<Route path='/reports/custom' element={<CustomReportsList />} />
							<Route path='/report/custom/:workspaceId/:reportId' element={<CustomReport />} />

							<Route path='/reports/native' element={<ReportList />} />
							<Route path='/report/native' element={<Report />} />
							<Route path='/report/native/:reportId' element={<Report />} />

							<Route path='/reports/account-insights' element={<AccountInsights />} />
							<Route path='/reports/cross-referenced-inventory' element={<ChannelManager />} />
							<Route path='/reports/pricing-and-inventory' element={<PricingAndInventory />} />
							<Route path='/reports/buy-opportunity' element={<BuyOpportunity />} />
							<Route path='/reports/new-product-introduction' element={<NewProductIntroduction />} />

							<Route path='/reports/compliance-insights' element={<ComplianceInsights />} />
							<Route path='/reports/part-substances/:partId' element={<PartSubstances />} />
							<Route path='/reports/substance-parts/:partListUid/:cas' element={<SubstanceParts />} />

							<Route path='/reports/account-insights' element={<AccountInsights />} />
							<Route path='/reports/lead-gen' element={<LeadGen />} />
							<Route path='/reports/pixel-insight-instructions' element={<PixelInstallInstructions />} />

							<Route path='/reports/top-500-parts' element={<Top500Parts />} />
							<Route path='/reports/product-movement' element={<ProductMovement />} />

							<Route path='/reports/part-search' element={<PartSearch />} />
							<Route path='/reports/part-search/:partNumberParam' element={<PartSearch />} />
							<Route path='/reports/part-search/:partNumberParam/:manufParam' element={<PartSearch />} />

							<Route
								path='/reports/part-compare/:partNumberParam/:manufParam/:crossPartNumberParam/:crossManufParam/:distiParam'
								element={<PartCompare />}
							/>

							<Route path='/reports/part-compare/:partNumberParam/:manufParam/:distiParam' element={<PartCompare />} />

							<Route path='/partlists/files/upload' element={<UploadFile />} />
							<Route path='/partlists/files/downloads' element={<DownloadsList />} />

							<Route path='/partlists' element={<PartlistManager />} />
							<Route path='/partlist/upload' element={<PartlistUploadManager />} />
							<Route path='/partlist' element={<Partlist />} />
							<Route path='/partlist/:partlistUid' element={<Partlist />} />

							<Route path='/substance-lists' element={<SubstanceListManager />} />
							<Route path='/substance-list/:substanceListUid' element={<SubstanceList />} />

							<Route path='/alerts' element={<AlertManager />} />
							<Route path='/alerts/:alertUidParam' element={<AlertManager />} />
							<Route path='/alerts/:alertUidParam/:dateParam' element={<AlertManager />} />

							<Route path='/reports/configs' element={<ConfigList />} />
							<Route path='/customers' element={<CustomerList />} />
							<Route path='/customers/:customerUid/service-plans' element={<ServicePlanList />} />
							<Route path='/users' element={<UserList />} />
							{/* <Route path='*' element={<NotFoundPage />} /> we should add this case to the app */}
						</Routes>
					</Suspense>
					</div>
					<Footer />
				</div>
			</>
		</div>
	);
}

export default App;
