function Footer() {
  return (
    <footer className="footer">
      <div className="py-8 mx-auto">
        <hr className="my-10 border-gray-200 dark:border-gray-700" />

        <div className="flex flex-col items-center sm:flex-row sm:justify-between">
          <p className="text-sm text-gray-400">
            © {new Date().getFullYear()} EETech
          </p>

          <div className="flex mt-3 -mx-2 sm:mt-0">
            <a
              href="/about"
              className="hidden mx-2 text-sm text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              aria-label="about"
            >
              About
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
