import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { breadCrumbsState } from "../core/controls/BreadCrumbs/breadCrumbsState";

function About() {
  const setBreadCrumbsState = useSetRecoilState(breadCrumbsState);

  useEffect(() => {
    setBreadCrumbsState([
      {
        title: "Home",
        navigateTo: "/",
      },
      {
        title: "About",
        navigateTo: "/about",
      },
    ]);
  }, []);

  return (
    <div className="page-section px-10 py-10 flex flex-col gap-4">
      <h1>About EETech Data Dashboard</h1>
    </div>
  );
}

export default About;
