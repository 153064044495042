const toastTypeClassNames = {
  info: "border-cyan-500 shadow-cyan-200 bg-cyan-100",
  success: "border-green-500 shadow-green-200 bg-green-100",
  error: "border-red-500 shadow-red-200 bg-red-100",
  warning: "border-yellow-500 shadow-yellow-200 bg-yellow-100",
};

function Toast(props) {
  return (
    <li
      className={
        toastTypeClassNames[props.item.toastType] +
        (props.fade ? " opacity-0 transition-opacity duration-1000" : "") +
        " relative border-2 rounded-md shadow-md w-full p-2 mb-2"
      }
    >
      <span>{props.item.message}</span>
      <button
        type="button"
        className="button-secondary absolute top-0 right-0 border-transparent bg-transparent"
        onClick={() => props.onRemove(props.item)}
      >
        X
      </button>
    </li>
  );
}

export default Toast;
