import { useEffect } from "react";

function Modal({ children, onOutsideClick }) {
	useEffect(() => {
		if (!onOutsideClick) {
			return;
		}
		const checkIfClickedOutside = (e) => {
			if (e.target.id === "modal-control" && typeof onOutsideClick !== "undefined") {
				onOutsideClick();
			}
		};
		document.addEventListener("click", checkIfClickedOutside);
		return () => {
			document.removeEventListener("click", checkIfClickedOutside);	
		};
	}, [onOutsideClick]);
	return (
		<div className='modal-control' id='modal-control'>
			<div className='modal bg-white rounded-sm drop-shadow-2xl opacity-100 z-20 py-[20px] px-3 m-8 mx-auto modal-children-container w-[520px]'>
				{children}
			</div>
		</div>
	);
}

export default Modal;
