import { useEffect } from "react";

import { useSetRecoilState, useRecoilValue } from "recoil";
import { toastsState } from "./toastsState";

import Toast from "./Toast";

const fadeDuration = 1000;

function ToastList() {
  const toasts = useRecoilValue(toastsState);
  const setToastsState = useSetRecoilState(toastsState);

  function onItemFade(payload) {
    if (payload) {
      const _items = [...toasts];
      const index = _items.indexOf(payload);
      if (index >= 0) {
        const item = { ..._items[index] };
        item.fade = true;
        _items.splice(index, 1, item);
        setToastsState(_items);
      }
    }
  }

  function onItemRemove(payload) {
    if (payload) {
      const _items = [...toasts];
      const index = _items.indexOf(payload);
      if (index >= 0) {
        _items.splice(index, 1);
        setToastsState(_items);
      }
    }
  }

  useEffect(() => {
    const now = new Date().valueOf();
    const nextExpiration = (toasts || [])
      .filter((t) => t.expiresAt)
      .reduce(
        (agg, t) => {
          if (t.expiresAt < agg.expiresAt) {
            agg = t;
          }

          return agg;
        },
        { expiresAt: now + 60 * 60 * 1000 }
      );

    if (nextExpiration.toastType) {
      if (nextExpiration.expiresAt <= now) {
        onItemRemove(nextExpiration);
        return;
      }

      setTimeout(() => {
        onItemFade(nextExpiration);
      }, nextExpiration.expiresAt - now - fadeDuration);
      setTimeout(() => {
        onItemRemove(nextExpiration);
      }, nextExpiration.expiresAt - now);
    }
  }, [toasts]);

  const toastList = (toasts || []).map((t, i) => (
    <Toast key={i} item={t} onRemove={onItemRemove} fade={!!t.fade} />
  ));

  return (
    <>
      <ul className="text-center">{toastList}</ul>
    </>
  );
}

export default ToastList;
