import React, { useState } from "react";

import { InformationCircleIcon } from "@heroicons/react/24/outline";

const InfoPopup = ({
	children,
	leftArrow = false,
	showOnTheRight = true,
	content = null,
	icon = null,
	isProxied = false,
	onShowProxy = null
}) => {

	const [isHovered, setIsHovered] = useState(false);

	function onMouseEnter(e){
		if (isProxied && onShowProxy) {
			onShowProxy(e, true, content, { leftArrow, showOnTheRight });
		}

		setIsHovered(true);
	}
	function onMouseLeave(e){
		if (isProxied && onShowProxy) {
			onShowProxy(null, false);
		}
		
		setIsHovered(false);
	}
	return (
		<div className={`${!isProxied ? "relative": "" } cursor-pointer info-popup`}>
			{icon == null ? (
				<div onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
					{children}
				</div>
			) : (
				<div>
					{children}
					<div onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
						{icon}
					</div>
				</div>
			)}
			{ !isProxied ? <>
				<div
					className={`info-popup-content absolute bg-white shadow-[0px_0px_10px_5px_rgba(150,150,150,0.3)] transition-all duration-300 ease-in-out p-2.5 rounded-lg 
					${leftArrow == true ? " ml-16 top-[5px] w-fit" : "w-fit min-w-[200px] top-[25px]"} 				
					${showOnTheRight ? "-left-2 " : "-right-2"}
					${ isHovered ? "z-[9999] opacity-100  pointer-events-auto" : " opacity-0 top-0 pointer-events-none"}`}>
					<div
						className={`${
							leftArrow == true ? "arrow-left-up-side" : `${showOnTheRight ? "arrow-up-left ml-1" : "arrow-up"}`
						} `}
					/>
					{content}
				</div>
			</> : <></>}
		</div>
	);
};

export default InfoPopup;
