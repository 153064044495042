import { useAuth0 } from "@auth0/auth0-react";
import { useRecoilState, useRecoilValue } from "recoil";
import { tokenState } from "./tokenState";
import { wrapEventCreate } from "./util";
import { userState } from "./userState";

function Logout() {
  const user = useRecoilValue(userState);
  const token = useRecoilState(tokenState);

  const { logout } = useAuth0();

  const onLogoutButtonClick = async () => {
    const eventCreate = wrapEventCreate(token[0]);
    await eventCreate({ 
      eventType: "logout"
    });

    logout({ returnTo: window.location.origin });
  };

  return (
    <button
      type="button"
      onClick={onLogoutButtonClick}
      className="text-brand-primary-700 hover:underline"
    >
      Log Out
    </button>
  );
}

export default Logout;
