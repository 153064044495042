
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import UserStatus from "../auth/UserStatus";

function Header() {

	const location = useLocation();
	const navigation = useNavigate();
	const [path, setPath] = useState("/");
	const [paths, setPaths] = useState([{ url: "/" }]);

	useEffect(() => {
		if(location.pathname.includes("/partlist/")){
			if(!path.includes("/partlists/")){
				let obj2 = { url: "/partlists"};
				setPaths((prev) => [...prev, obj2]);
			}
		}

		if(location.pathname.includes("/reports/part-compare/")){
			if(!path.includes("/reports/cross-referenced-inventory")){
				let obj2 = { url: "/reports/cross-referenced-inventory"};
				setPaths((prev) => [...prev, obj2]);
			}
		}
		
		if (location.pathname + "" + location.search != path) {
			let obj = { url: location.pathname + "" + location.search };
			setPaths((prev) => [...prev, obj]);
			setPath(location.pathname + "" + location.search);
		} 
	}, [location]);


	const handleGoBack = () => {
		let UrlPaths = paths;
		let urltmt;
		do {
			urltmt = UrlPaths.pop();
		} while (window.location.pathname + "" + window.location.search == urltmt.url);
		navigation(urltmt.url);
	};

	return (
		<div>
			<nav id='header' className='header'>
				<div className='logo-container'>
					<img src="/VDI-Logo-white-text.png" />
				</div>
				<div className='user-status-container' >
					<UserStatus />
				</div>
			</nav>
		</div>
	);
}

export default Header;
