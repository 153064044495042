import { NavLink } from "react-router-dom";
import { useEffect } from "react";

import { useSetRecoilState } from "recoil";
import { breadCrumbsState } from "../core/controls/BreadCrumbs/breadCrumbsState";

function Home() {
	const setBreadCrumbsState = useSetRecoilState(breadCrumbsState);

	useEffect(() => {
		setBreadCrumbsState([
			{
				title: "Home",
				navigateTo: "/",
			},
		]);
	}, []);

	return (
		<div className='page-section px-10 py-10 flex flex-col gap-4'>
			<h1>Welcome to the EETech Data Portal</h1>

			<div>
				<img src="/VDI-dashboard.png" />
			</div>
		</div>
	);
}

export default Home;
